import React from 'react';
// import Home
import Footer from '../auth/Footer';
import Header from '../auth/Header';


const GST = () => {
    return (
        <div>
            <Header />
            <section className="site-content about-page-section cmn-banner-bg cmn-pad">
                <div className="container">
                    <div className="about-us-content desclaimer">
                        <h3 className="text-center page-title">GST Policy</h3>
                        <div className='about-details-sec'>
                            <h3>GST Applicability</h3>
                            <p>GST (Goods and Services Tax) applies to the platform fee (rake fee) charged by Go Rummy. As per the GST Act, 2017, a 28% GST rate is levied on online gaming services at face value effective October 01, 2023.</p>
                            <p>GST will be deducted at the time of adding cash to the account. There will however no GST if an entry fee is charged / number of games played or the amount won.</p>
                            <p>GST cashback up to 100% will be subject to sole discretion of Go Rummy.</p>

                            <h3>Calculation of GST</h3>
                            <p>GST is applicable only to the platform fee collected by Go Rummy and is not deducted from user winnings. The platform fee, after the GST deduction, is non-refundable.</p>

                            <h3>GST Invoice & Compliance</h3>
                            <p>Go Rummy issues GST-compliant invoices for platform fees paid by users.</p>
                            <p>Users are not eligible to claim GST input credit unless they are registered as a business entity under GST law.</p>

                            <h3>Compliance with Government Regulations</h3>
                            <p>Go Rummy reserves the right to modify GST policies based on statutory amendments and government directives</p>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default GST