import React from 'react';
import Footer from '../auth/Footer';
import Header from '../auth/Header';
const TermsAndConditions = () => {
    return (
        <>
            <Header />

            <section className="site-content about-page-section cmn-banner-bg cmn-pad">
                <div className="container">
                    <div className="about-us-content desclaimer">
                        <h3 className="text-center page-title text-2xl font-bold mb-4">Terms Of Condition</h3>
                        <div className='about-details-sec'>
                            <h3>1.Introduction to Go Rummy:</h3>
                            <p>Welcome to Go Rummy, the premier online platform for playing rummy in India. By accessing or using any services offered by Go Rummy, including browsing the website, registering an account, or participating in any rummy games, you acknowledge and agree to be bound by these Terms of Service, along with our Privacy Policy and any other guidelines or rules applicable to our services. These Terms govern your relationship with Go Rummy and are designed to ensure a safe, fair, and enjoyable gaming experience for all users.</p>

                            <h3>2. User Account Registration and Security:</h3>
                            <p>To fully access the features and benefits of Go Rummy, you must register a user account. During registration, you are required to provide accurate, current, and complete information, including your name, date of birth, email address, and contact details. You agree to keep your account information updated at all times.</p>
                            <h5 className='mb-3'>Account Security:</h5>
                            <p>You are solely responsible for maintaining the confidentiality of your username and password. Any activity conducted through your account will be deemed to have been authorized by you. If you suspect unauthorized use of your account, you must immediately notify us.</p>
                            <h5 className='mb-3'>Multiple Accounts:</h5>
                            <p>Each user is allowed to register only one account on Go Rummy. Creating multiple accounts for the same individual is strictly prohibited and may result in account termination.</p>

                            <h3>3.Eligibility Criteria for Participation:</h3>
                            <p>To use Go Rummy and participate in any cash or free rummy games, you must meet the following eligibility criteria:</p>
                            <p>Age Requirement: You must be at least 18 years old to register and play rummy on Go Rummy.</p>
                            <p>Legal Competence: You must have the legal capacity to enter into a binding contract under the Indian Contract Act, 1872.</p>
                            <p>Jurisdictional Compliance: You must ensure that your participation on our platform complies with the laws of your jurisdiction. We strictly prohibit access to our services from states where online rummy is illegal, including Assam, Odisha, Telangana, Sikkim, Nagaland, and Andhra Pradesh.</p>
                            <p>By using our services, you confirm that you meet all these eligibility requirements and agree to provide proof of eligibility upon request.</p>

                            <h3>4.Responsible Gaming and Player Protection at Go Rummy:</h3>
                            <p>We are committed to promoting responsible gaming practices. We provide a variety of tools and resources to help you manage your gaming activities and prevent compulsive gambling.</p>
                            <h5 className='mb-3'>These include:</h5>
                            <p>Self-Exclusion: You can request Go Rummy for a temporary or permanent self-exclusion from our platform if you feel that your gaming is becoming problematic.</p>
                            <p>Time Limits: Set session time limits to ensure you do not spend excessive amounts of time playing rummy.</p>
                            <p>Support Resources: Access links to external organizations that offer support for gambling addiction.</p>
                            <p>If you believe that your gaming is negatively impacting your life, we encourage you to use these tools and seek help. Remember, playing rummy should be a fun and entertaining activity, not a financial burden or a source of stress.</p>

                            <h3>5.Use of Services:</h3>
                            <p>By accessing and using Go Rummy, you agree to the following terms regarding your use of our services:</p>
                            <p>Lawful Use: You must use our services only for lawful purposes and in compliance with all applicable laws and regulations.</p>
                            <p>Prohibited Activities: You must refrain from engaging in any activities that could harm the integrity of our platform or the fairness of the games, including but not limited to cheating, collusion, hacking, or using automated software (bots) to play games.</p>
                            <p>Content Integrity: You must not post or share any content that is defamatory, offensive, obscene, or infringes on the intellectual property rights of others.</p>
                            <p>Fair Play: You agree to participate in games fairly and not to exploit any technical flaws or loopholes in the platform to gain an unfair advantage.</p>
                            <p>Any violation of these terms may result in the suspension or termination of your account and a permanent ban from the platform.</p>

                            <h3>6.Intellectual Property Rights:</h3>
                            <p>All content available on Go Rummy, including but not limited to software, games, graphics, text, logos, trademarks, and user interfaces, is the exclusive property of Go Rummy or its licensors. You are granted a limited, non-exclusive, non-transferable license to access and use the platform solely for personal entertainment purposes.</p>
                            <p>You may not reproduce, copy, distribute, or modify any part of our content without our prior written consent.</p>
                            <p>Create Derivative Works: Develop or create derivative works based on any content from our platform.</p>
                            <p>Commercial Use: Use any part of our platform for commercial purposes without explicit authorization.</p>
                            <p>Any unauthorized use of our intellectual property may result in legal action and termination of your access to the platform.</p>

                            <h3>7. Limitation of Liability:</h3>
                            <p>Under no circumstances, including but not limited to negligence, shall Go Rummy be liable for any direct, indirect, incidental, special, consequential, or punitive damages arising out of or in connection with your access to, use of, or inability to use our services. This includes, but is not limited to:</p>
                            <p>Loss of Profits: Any loss of income, profits, or anticipated savings.</p>
                            <p>Loss of Data: Any loss or corruption of data, including game history and account details</p>
                            <p>Business Loss: Any loss of business, goodwill, or reputation.</p>
                            <p>Technical Failures: Any technical failures, system breakdowns, or interruptions in service, including those caused by third-party service providers</p>
                            <p>Our maximum aggregate liability to you in any circumstance, other than for the payment of any withdrawable balance in your user account, shall not exceed INR 1,000.</p>
                            <p>You also agree to indemnify and protect Go Rummy, its directors, officers, employees, agents, representatives, and its affiliates from all claims (including legal fees), liabilities, actual or consequential damages, losses, and expenses arising from, or in any way connected to, third-party claims related to (a) your use of our Services (including any actions taken by a third party using the User's account), and (b) your violation of these Terms. In the event of such a claim or legal action ('Claim'), Go Rummy will make an effort to notify you using the contact information associated with your account held by us (provided that failure to deliver such notice will not exempt or reduce your obligation to indemnify as described herein).</p>

                            <h3>8. Disclaimer of Warranties:</h3>
                            <p>Go Rummy provides its services on an "as is" and "as available" basis, without any warranties of any kind, express or implied. We do not warrant that:</p>
                            <p>Uninterrupted Access: Our platform will be available at all times without interruption</p>
                            <p>Error-Free Operation: The services provided will be free from errors, defects, or technical glitches.</p>
                            <p>Security: The platform will be secure and free from viruses or other harmful components</p>
                            <p>Accuracy: The information, content, or services provided will be accurate, complete, or reliable.</p>
                            <p>We disclaim all warranties, including but not limited to warranties of merchantability, fitness for a particular purpose, and non-infringement.</p>

                            <h3>9. Governing Law and Jurisdiction:</h3>
                            <p>These Terms of Service shall be governed by and construed in accordance with the laws of India. Any disputes, controversies, or claims arising out of or in connection with these terms shall be subject to the exclusive jurisdiction of the civil courts located in Chandigarh.</p>

                            <h3>10. Modifications to Terms of Service:</h3>
                            <p>Go Rummy reserves the right to modify or update these Terms of Service at any time, without prior notice. Any changes will be effective immediately upon posting the revised terms on our website.</p>
                            <p>Go Rummy’s failure to assert or enforce any right or provision stated here does not waive that right or provision in this or any other instance. Should any provision mentioned here be deemed invalid, the remaining provisions will remain in full force and effect. If any provision is considered unlawful, void, or unenforceable for any reason, it will be considered separate from these Terms and Conditions and will not impact the validity and enforceability of the remaining provisions.</p>
                            <p>These Terms and Conditions constitutes the complete agreement concerning our services, and they override and replace any previous agreements between the parties</p>
                            <p>Your continued use of our services after any changes to the terms signifies your acceptance of the updated terms. If you do not agree with the changes, you must stop using our services and close your account immediately.</p>
                            <p>The Terms and Conditions are classified as 'electronic records' as defined by applicable information technology laws and respective rules, which may be amended from time to time. These Terms and Conditions are generated by a computer system and do not necessitate any physical or digital signatures</p>
                            <p>In case users have any further queries or need clarification on these Terms and Conditions, Kindly contact us at the coordinates mentioned on our website. ( <a href='https://rummy246.in'>https://rummy246.in</a> )</p>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default TermsAndConditions;