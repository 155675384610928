import React from 'react'
import Header from '../auth/Header'
import Footer from '../auth/Footer'

const RiskMonitoringPolicy = () => {
    return (
        <div>
            <Header />
            <section className="site-content about-page-section cmn-banner-bg cmn-pad">
                <div className="container">
                    <div className="about-us-content desclaimer">
                        <h3 className="text-center page-title">Risk Monitoring</h3>
                        <div className='about-details-sec'>
                            <h3>Introduction</h3>
                            <p>Go Rummy is committed to maintaining a fair, secure, and transparent gaming environment. This Risk Monitoring Policy outlines our approach to identifying, assessing, and mitigating risks associated with fraudulent activities, financial transactions, and gameplay integrity while ensuring compliance with Indian laws and regulations</p>

                            <h3>Objectives</h3>
                            <p>The key objectives of this policy include:</p>
                            <ol>
                                <li>Ensuring compliance with legal and regulatory requirements along with the draft Online Gaming (Regulation) Bill, 2023 when it comes into effect.</li>
                                <li>Preventing fraudulent activities such as money laundering, multiple account creation, and collusion</li>
                                <li>Protecting users from unfair gameplay and maintaining platform integrity</li>
                                <li>Monitoring financial transactions to detect suspicious patterns</li>
                            </ol>

                            <h3>Legal & Regulatory Compliance</h3>
                            <p>Go Rummy operates in compliance with:</p>
                            <ol>
                                <li>The Information Technology (IT) Act, 2000 and IT Rules, 2021</li>
                                <li>The Prevention of Money Laundering Act (PMLA), 2002 for AML compliance</li>
                                <li>The Foreign Exchange Management Act (FEMA), 1999 (for cross-border transactions)</li>
                                <li>The Income Tax Act, 1961 (for TDS on winnings)</li>
                                <li>Reserve Bank of India (RBI) Guidelines on digital payments</li>
                                <li>State-Specific Gaming Laws (users from restricted states cannot participate)</li>
                                <li>The Consumer Protection (E-Commerce) Rules, 2020 – Ensuring transparent business practices for online platforms</li>
                                <li>The Gambling (Online Gaming) Legislation Bill, 2023 – In line with the proposed legislative framework for online gaming in India</li>
                            </ol>

                            <h3>Risk Identification and Assessment</h3>
                            <p>Go Rummy employs a combination of automated systems and manual reviews to identify and assess risks, including:</p>
                            <ol>
                                <li>Account Fraud: Detection of multiple accounts, fake identities, and use of stolen credentials. Financial Fraud: Monitoring unusual deposit and withdrawal patterns</li>
                                <li>Gameplay Manipulation: Identifying collusion, bot usage, or unfair advantages</li>
                                <li>Responsible Gaming Risks: Tracking excessive gaming behaviours that may indicate compulsive gambling</li>
                                <li>Tax Evasion Risks: Ensuring that users are compliant with the Income Tax Act, particularly regarding the declaration of winnings by way of deduction of appropriate TDS amounts</li>
                            </ol>
                            <p>Fraud Detection Mechanisms to mitigate risks, Go Rummy utilizes:</p>
                            <ol>
                                <li>AI-based Monitoring Systems: Automated systems analyse transaction patterns and gameplay behaviours</li>
                                <li>Manual Review Teams: A dedicated team investigates flagged accounts and transactions</li>
                                <li>Geolocation and IP Tracking: Ensures compliance with jurisdictional laws and prevents multi-accounting</li>
                                <li>Transaction Limits and Verification: Users with high transaction volumes undergo additional scrutiny</li>
                            </ol>

                            <h3>User Authentication and Verification</h3>
                            <p>To enhance security, Go Rummy implements:</p>
                            <ol>
                                <li>KYC (Know Your Customer) Requirements: Users must submit valid government-issued ID proof before making withdrawals and should be mandatorily more than 18 years of age</li>
                                <li>Two-Factor Authentication (2FA): Users are encouraged to enable 2FA for additional security</li>
                                <li>Device and Browser Fingerprinting: Prevents unauthorized access and multiple account creation</li>
                                <li>Regular KYC Re-verification – In compliance with ongoing regulations, periodic re-verification may be required for continued access to the platform.</li>
                            </ol>

                            <h3>Restricted States & Jurisdictions</h3>
                            <p>As per state laws, real-money gaming is prohibited in certain Indian states. Go Rummy does not permit users from:</p>
                            <ol>
                                <li>Tamil Nadu</li>
                                <li>Andhra Pradesh</li>
                                <li>Telangana</li>
                                <li>Karnataka</li>
                                <li>Odisha</li>
                                <li>Assam</li>
                                <li>Sikkim</li>
                                <li>Nagaland</li>
                            </ol>
                            <p>Attempts to bypass these restrictions using VPNs, fake locations, or third-party accounts may result in account suspension and fund forfeiture.</p>

                            <h3>Monitoring of Financial Transactions</h3>
                            <p>Go Rummy continuously monitors financial transactions to detect irregularities, including:</p>
                            <ol>
                                <li>Frequent large deposits and withdrawals</li>
                                <li>Sudden changes in transaction behaviours</li>
                                <li>Transactions involving multiple accounts with the same credentials or devices</li>
                                <li>Unusual winnings or losses within short timeframes</li>
                                <li>Use of non-Indian payment gateways that may pose a risk of regulatory violations (e.g., cross-border transactions)</li>
                            </ol>
                            <p>Users from states like Kerala, Gujarat and others with evolving regulations will be closely monitored to ensure compliance with emerging laws.</p>

                            <h3>Fair Play and Responsible Gaming</h3>
                            <p>To maintain a fair and responsible gaming environment, Go Rummy: Implements strict fair play policies against collusion, bot usage, and cheating. Allows users to set self-imposed deposit limits. Provides self-exclusion options for players facing gaming addiction issues. Conducts random audits and game integrity checks. Educates users about the risks of online gambling and promotes awareness about responsible gambling practices, as mandated by emerging Indian regulations.</p>

                            <h3>Handling of Violations</h3>
                            <p>If any suspicious activity is detected, the following actions may be taken: Account Suspension or Termination: Users violating fair play rules or engaging in fraudulent activities may have their accounts suspended or permanently banned. Funds Freezing and Confiscation: Any funds associated with fraudulent activities may be withheld or forfeited. Legal and Regulatory Reporting: Suspected money laundering or other illegal activities will be reported to relevant authorities. Reporting to the Ministry of Electronics and Information Technology (MeitY) as per the Intermediary Guidelines and Digital Media Ethics Code, 2021, if applicable</p>

                            <h3>Customer Support and Dispute Resolution</h3>
                            <ol>
                                <li>Email: <a href="mailto:support@rummy246.in">support@rummy246.in</a></li>
                                <li>Phone Support: Dedicated helpline for immediate concerns - <a href="tel:1800 890 2466">1800 890 2466</a></li>
                                <li>Through contact form in our website</li>
                            </ol>

                            <h3>Policy Updates</h3>
                            <p>Go Rummy reserves the right to update this Risk Monitoring Policy as needed to enhance security and compliance. Users will be notified of any significant changes through email or in-app notifications.</p>
                            <p>By using Go Rummy’s services, users agree to abide by this Risk Monitoring Policy and cooperate with any risk assessment processes conducted by the platform.</p>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default RiskMonitoringPolicy