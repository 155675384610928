import React from 'react'
import Footer from '../auth/Footer'
import Header from '../auth/Header'

const Privacy = () => {
    return (
        <div>
            <Header />
            <section className="site-content about-page-section cmn-banner-bg cmn-pad">
                <div className="container">
                    <div className="about-us-content desclaimer">
                        <h3 className="text-center page-title">Privacy Policy</h3>
                        <div className='about-details-sec'>
                            <h3>Objective and Purpose of the Privacy Policy</h3>

                            <p>Go Rummy is committed to safeguarding Player’s privacy and establishing confidence and trust. This Privacy Policy outlines our privacy practices concerning personal information collected when player opt for our services, products, features or content via any website, application or any other digital or physical mode, even if player have not created an account or registered with us. We reserve the right, at our discretion, to modify this Privacy Policy from time to time. The updated version shall be made available on Our website or can be collected from our registered office during working days / hours.</p>
                            <p>This page updates our policies concerning the collection, utilization, and disclosure of personal information when using the Service / Product / Application / Website.</p>
                            <p>This Privacy Policy is in compliance with the following Acts and as amended thereof and other applicable law enacted from time to time.</p>
                            <ol>
                                <li>Sec 43A of the Information Technology Act, 2000</li>
                                <li>Regulation 3(1) of the Information Technology (Intermediaries Guidelines) rules, 2011</li>
                                <li>Regulation 4 of the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011 (the “SPI Rules”)</li>
                            </ol>
                            <p>Go Rummy will only utilize or disclose a player’s information as outlined in this Privacy Policy.</p>
                            <p>The information gathered from users, encompassing individual personal information along with objectives, methods, and procedures involved in gathering, utilizing, processing, retaining, accessing, disclosing, and disposing of the collected information is clearly stated in this Privacy Policy. The statements and procedures mentioned under this Privacy Policy must be read in conjunction with our general Terms and Conditions, available on the Website. Unless specified otherwise in this Privacy Policy, terms mentioned here have the same definitions as those found in Our Terms and Conditions.</p>

                            <h3>Applicability and Acceptance of these terms</h3>
                            <p>This Privacy Policy is applicable to Personal Information (referring to any/all information/ documents/ details that relates to a natural person, which either directly or indirectly in combination with other information is available with the Company, can identify such person) and such other information as mentioned further in this Privacy Policy.</p>
                            <p>By visiting and/or using/downloading our website, players are accepting and consenting to the practices described in this Privacy Policy. Players accept this Privacy Policy when they visit our website, sign up for, access, or use our products, services, content, features, technologies or functions offered on our website and all related sites, applications, and services unless otherwise agreed through contract.</p>

                            <h3>Review and Updates of Privacy Policy</h3>
                            <p>This Privacy Policy may be amended/updated at any point of time by way of posting the latest version on our website. The policy effective date shall be the published date of the policy on our website. Upon significant changes to this Privacy Policy, prior notice shall be provided by way of publishing this Privacy Policy at least 15 days in advance on our website. After 15 days, it will be considered as expressly consented to all the amendments in this Privacy Policy by the players.</p>

                            <h3>Security practices protecting personal information</h3>
                            <p>In this Privacy Policy, the term "personal information" refers to data that can be linked to a particular individual, enabling their identification. We do not classify personal information to encompass data that has been anonymized in a way that prevents the identification of a specific user.</p>
                            <p>Your personal information is stored and processed on our computers within our facilities or in our partner’s facilities. We employ a combination of physical, technical, and administrative security measures to mitigate the potential risks of loss, misuse, unauthorized access, disclosure, and alteration. These safeguards include firewalls, data encryption, physical access controls at our data centres, and authorization controls for information access. We have taken comprehensive measures to ensure that our security practices align with the relevant laws and meet industry standards.</p>

                            <h3>Information collected and Uses</h3>
                            <p>The personal information provided by the user is used by Go Rummy to enhance and deliver the superior service over time. By utilizing / assessing the Service / Product / Application / Website, the user consents to the collection and use of information as stated in this policy. Storing personal information of Player’s, is therefore, our top priority. The following types of personal information is collected by us, from time to time.</p>
                            <h5>A. Personal Information</h5>
                            <ul>
                                <li>Contact Information: This includes your name, residential or office address, email address, phone number, date of birth, and similar details. We collect this information to facilitate seamless access across all our platforms and for verification purposes to authenticate our players.</li>
                                <li>Extended Information: This may encompass details such as names and dates of birth of family members, educational background, occupation, and other related information. We gather this data to ensure the efficient processing of our services and access it strictly on a need-to-know basis.</li>
                                <li>Financial Information: This comprises bank account details (including account numbers and types), Permanent Account Number (PAN), and similar financial information. We require this data to process transactions smoothly and access it solely as necessary to deliver our services.</li>
                                <li>Conversation Information such as phone call recordings, email records and similar information – This information is required not only for internal audit purposes but also for quality monitoring and improvement purposes</li>
                                <li>Through Third Party - We may also collect information from or about players from other sources, such as through your contact with us, including our customer support team, results when players respond to a survey/marketing email, players interactions with us or other companies (subject to their privacy policies and applicable law), and from other accounts wherein we have reason to believe players consented in sharing information (whether in part or in whole)</li>
                                <li>Testimonials / Customers speak – Players personal information can be used on our website which shall be voluntarily provided, on / without our request and is applicable across our products / services and offerings. Players may contact us in case they want to delete / update such testimonials on our website or other accounts / pages</li>
                            </ul>
                            <h5>B. Device Information</h5>
                            <ul>
                                <li>We collect information sent to us from your computer, mobile or other similar device sent either while usage of our products / services or expressly shared over voice or electronically of similar mode - This information is required to enhance your viewing experience and analyse information for improvement in shortcomings</li>
                                <li>The information might include data on the pages you have accessed, IP Address, Device ID, Device unique identifier, device type, device geo-location, computer and connection information, mobile network information, mobile application interactions, mobile application crash logs and diagnostics, statistics on page views, traffic to and from the sites, referral URL, ad data, and standard web log data and other information – This information is required to enhance your viewing experience and analyse information for improvement in shortcomings</li>
                            </ul>
                            <h5>C. Cookies Information</h5>
                            <p>We collect anonymous information through our use of cookies / similar and web beacons. We may collect and store any information You provide Us when You use any of Our products / services or visit our website or any other webpage, including when You add information on a web form, add or update your account information, participate in community discussions, chats, or dispute resolutions, or when You otherwise correspond with Us regarding Our products / service offerings. This helps Us analyse Our Website page flow, measure promotional effectiveness, and promote trust and safety and for analytical study. Cookies are small data files that are stored on your device hard drive / storage and web beacons are tiny graphics embedded on webpages, assisting Us in providing Our services. We offer certain features that are only available using Cookies / Web beacons</p>
                            <p>Our sites include social media features that all you to share and like the contents on third party sites. These features may be hosted by the third-party sites or Our Website, any may collected your IP Address, the pages you are viewing, and may set a cookie to enable the feature to function. You can change Your browser setting to refuse Out cookies, but this may interfere with Your use of our products / service offerings on Our Website. Third parties with whom we partner to provide some content such as video clips, use Flash cookies which cannot be removed using your browser settings. To learn how to manage your Flash cookie preferences, visit the Flash Player Settings Manager Help page.</p>
                            <p>This Privacy Policy does not apply to third party cookies, web beacons, social media features and widgets (such as the Facebook Like button), or other tracking technologies used by third parties. We are not responsible for the privacy policies and practices of such third parties</p>
                            <h5>D. Express consent Information</h5>
                            <ul>
                                <li>By visiting our website or availing our products / services / offerings, players expressly consent to us (including our marketing channels and business partners) to contact them t through SMS/ Call / email and to follow up call in relation to our products / services/ offerings, for imparting product knowledge, informing promotional offers being offered from time to time</li>
                                <li>Players hereby authorize and expressly consent us to share your personal information with third parties including but not limited to banks, credit information bureaus, government agencies for completion of the services availed</li>
                                <li>If at any given point of time, during or after the products / services / offerings, Players have concerns on withdrawal of consent provided to us, they can reach out to us directly÷</li>
                                <li>Players also hereby agree that in the condition of not providing the consent or withdrawing the consent during the tenure of service, which is mandatorily required by us, Go Rummy reserve the right to stop providing the services for which the said information and/or data was sought÷</li>
                                <li>User Rights & Control: Explicitly list user rights, such as the right to access, rectify, or delete their personal data.</li>
                                <li>Third-Party Sharing Transparency: You mention sharing data with third parties but should specify categories of third parties (e.g., analytics providers, payment processors)</li>
                            </ul>

                            <h3>Use of information collected</h3>
                            <p>The primary objective of collecting and storing personal information is to provide smooth and secure experience. The information can be used for (but not limited to) following uses.</p>
                            <ul>
                                <li>Supply our product and service to you and customize offerings</li>
                                <li>Notify Players regarding their account activity (not limited to processing and updation on transactions) and provide timely reminders on transactions</li>
                                <li>Provide customer support and resolve disputes / response to resolve disputes</li>
                                <li>Verify players identity during phone call or while account creation or password reset processes</li>
                                <li>Identify, prevent and report potentially prohibited, fraudulent or illegal activities</li>
                                <li>To identify and address any technical troubleshoots or advancements</li>
                                <li>Use players browsing patterns for improved page flow and experience and customised offerings</li>
                                <li>Contact players wherever required and not limited to our offerings</li>
                                <li>Generate and Maintain user profiles in our systems</li>
                                <li>Any other purpose during availing of our offering, by players</li>
                                <li>Preserve history as may be required by existing law or policy</li>
                            </ul>

                            <h3>Sharing and Purpose of information collected</h3>
                            <p>The insights we gain from Players are instrumental in tailoring and enhancing their experience with our products/ services offering. Players information aids Go Rummy in refining our platform, as well as in the prevention and detection of fraudulent activities or misuse. Additionally, it enables third parties to perform technical, logistical, or other functions on our behalf.</p>
                            <p>In this regard, we may share players’ personal information (not limited to) as per details here in-</p>
                            <ul>
                                <li>When players are involved in a transaction with us or our partnered institution</li>
                                <li>With third parties, vendors, affiliates, and merchants (anybody that we partner with for creation of product / service offering to players) to provide, maintain and improve our products / services offerings during course of business or on your request</li>
                                <li>If we have a reasonable belief that disclosure is necessary to adhere to relevant laws, legal proceedings, or government requests; to probe or initiate legal proceedings against an individual potentially infringing upon our rights, terms and conditions, or the rights of others; or to ensure the safety and security of us, our players, or the general public</li>
                                <li>With a third party, if Go Rummy is involved in a merger, acquisition, or sale of all or a portion of our stock or assets. If this occurs, players will be notified of any change to this Privacy Policy, as well as any choices you may have regarding your personal information</li>
                                <li>We may share aggregated, non-personally identifiable information with third parties</li>
                                <li>Other than stated in this Privacy Policy, we do not disclose or sell or rent any of players personal information to third parties without their consent, for any reason, at any time. It may be noted that third parties, such as vendors, merchants that you pay and third-party websites that players visit, have their own Privacy Policy. We are not responsible for the policies and practices of such third parties</li>
                            </ul>

                            <h3>Retention of Personal Information</h3>
                            <p>Go Rummy will keep Player’s information on file for the duration required to furnish players with the products or services offerings accessible on our website, or until you request the closure of player’s account with us, whichever occurs later. Additionally, we may have a legal or contractual obligation to maintain specific information in accordance with the relevant laws and/or our contractual commitments with Banks/Financial Institutions. As a result, such information will be held in compliance with the applicable laws or our contractual obligations.</p>
                            <p>After concluding our association, Go Rummy may still utilize your anonymized data, either on its own or merged with anonymized data from other users. We employ this aggregated anonymized data for tasks like data analysis, profiling, and research, allowing us to better understand our users and their characteristics. Your contact information, along with any application details players provided, may be retained for the purpose of preventing fraud and for use in legal matters, such as the pursuit or defence of a legal claim, or as evidence in legal proceedings.</p>

                            <h3>Opting out of promotional emails</h3>
                            <p>Players may choose not to receive promotional emails from us either by clicking the unsubscribe link at the bottom of any promotional email that we send them to remove their email address from any future promotional email campaigns. In case of any issues faced, players may reach us through details mentioned in contact us page on our website.</p>

                            <h3>Intimation regarding in personal information</h3>
                            <p>If there is any change in personal information shared by players with us, they may reach us through details mentioned in contact us page on our website</p>
                            <p>Go Rummy implements encryption, two-factor authentication (2FA), and secure servers to protect user data. Users must keep their login credentials confidential to prevent unauthorized access.</p>
                            <p>Users may request account data deletion or modifications by emailing <a href="mailto:support@rummy246.in">support@rummy246.in</a>.</p>
                            <p>Users have the right to access, rectify, or object to the processing of their personal data.</p>

                            <h3>Important Disclaimers</h3>
                            <p>If players choose to use our referral service to tell a friend about us, we may ask them for theirs friend’s name, phone number and email address. We will automatically send their referral / friend a message inviting him or her to visit the website. We store this information for the sole purpose of sending this message and tracking the success of our referral program.</p>

                            <h3>Contacting Us</h3>
                            <p>If a player has any questions regarding our Privacy Policy, he/she may contact us through our contact us page on our website or as per coordinates provided on our website.</p>
                            <p>As our business evolves, so will Our Privacy Policy and Terms and Conditions of use. We may send occasional email reminders about our notices and conditions, unless players have advised us not to do so. However, it is advisable to regularly visit our website to stay updated on recent changes.</p>
                            <p>Unless specified otherwise, our existing Privacy Policy covers all the information we have about the players. We are committed to upholding the commitments we make, and we will never significantly alter our policies and procedures to reduce the level of protection for personal information collected in the past without obtaining consent from the affected owner.</p>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default Privacy