/* eslint-disable no-unused-vars */
import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import Home from '../views/auth/index';
import Lobby from '../views/lobby/index';
import myAccount from '../views/myAccount/MyProfile/index';
import GameTransactions from '../views/myAccount/GameTransactions';
import TableTransactions from '../views/myAccount/GameTransactions/TableTransactions/index';
import TournamentTransactions from '../views/myAccount/TournamentTransactions';
import Passbook from '../views/myAccount/Passbook';
import Bonus from '../views/myAccount/Bonus/index';
import Invite from '../views/myAccount/Invite/index';
import Withdraw from '../views/myAccount/Withdraw/index';
import DepositCash from '../views/depositCash/index';
import Report from '../views/myAccount/Report/index';
import ChangePassword from '../views/myAccount/ChangePassword/index';
import Offers from '../views/offers/index';
import AboutUs from '../views/about/AboutUs';
import Contact from '../views/about/Contact';
import Faq from '../views/about/Faq';
// import blog from '../views/about/blog';

import PublicRoutes from './PublicRoutes';
import PrivateRoutes from './PrivateRoutes';
import download from '../views/about/download';
import tutorials from '../views/about/tutorials';
import legality from '../views/about/Legality';
import TermsOfService from '../views/about/terms-of-service';
import Privacy from '../views/about/Privacy';
import Blog from '../views/about/blog';
import BlogInner from '../views/about/Blog-inner';
import Disclaimer from '../views/about/Disclaimer';
import ReferEarn from '../views/about/Refer&Earn';
import HowToPlay from '../views/about/how-to-play';
import ScrollToTop from '../components/ScrollToTop';
import ReportedProblems from '../views/myAccount/Report/Reported/index';
import ContactSupport from '../views/about/ContactSupport';
import RefundsCancellations from '../views/about/Refunds-Cancellations';
import WithdrawalPolicy from '../views/about/Withdrawal-Policy';

import TDS from '../views/about/TDS';
import GST from '../views/about/GST';
import RiskMonitoringPolicy from '../views/about/RiskMonitoringPolicy';
import KycPolicy from '../views/about/KycPolicy';
import CustomerOnboarding from '../views/about/CustomerOnboarding';

const Routes = () => (
    <ScrollToTop>
        <Switch>
            <PublicRoutes path="/" component={Home} exact />
            <PublicRoutes path="/about-us" component={AboutUs} exact />
            <PublicRoutes path="/TDS" component={TDS} exact />
            <PublicRoutes path="/GST" component={GST} exact />
            <PublicRoutes path="/customer-onboarding-policy" component={CustomerOnboarding} exact />
            <PublicRoutes path="/risk-monitoring-policy" component={RiskMonitoringPolicy} exact />
            <PublicRoutes path="/kyc-policy" component={KycPolicy} exact />
            <PublicRoutes path="/contact" component={Contact} exact />
            <PublicRoutes path="/faq" component={Faq} exact />
            <PublicRoutes path="/download" component={download} exact />
            <PublicRoutes path="/how-to-play" component={HowToPlay} exact />
            <PublicRoutes path="/contact-support" component={ContactSupport} exact />
            <PublicRoutes path="/tutorials" component={tutorials} exact />
            <PublicRoutes path="/legality" component={legality} exact />
            <PublicRoutes path="/terms-of-service" component={TermsOfService} exact />
            <PublicRoutes path="/privacy" component={Privacy} exact />
            <PublicRoutes path="/blog" component={Blog} exact />
            <PublicRoutes path="/blog-inner" component={BlogInner} exact />
            <PublicRoutes path="/disclaimer" component={Disclaimer} exact />
            <PublicRoutes path="/referearn" component={ReferEarn} exact />
            <PublicRoutes path="/refunds-cancellations" component={RefundsCancellations} exact />
            <PublicRoutes path="/withdrawal-policy" component={WithdrawalPolicy} exact />
            <PrivateRoutes path="/tearms-of-service-prv" component={TermsOfService} exact />
            <PrivateRoutes path="/about-us-prv" component={AboutUs} exact />
            <PrivateRoutes path="/how-to-play-prv" component={HowToPlay} exact />
            <PrivateRoutes path="/faq-prv" component={Faq} exact />
            <PrivateRoutes path="/privacy-prv" component={Privacy} exact />
            <PrivateRoutes path="/lobby" component={Lobby} exact />
            <PrivateRoutes path="/my-account" component={myAccount} exact />
            <PrivateRoutes path="/change-password" component={ChangePassword} exact />
            <PrivateRoutes path="/game-transaction" component={GameTransactions} exact />
            <PrivateRoutes path="/game-transaction/table-transaction/:id" component={TableTransactions} exact />
            <PrivateRoutes path="/tournament-transaction" component={TournamentTransactions} exact />
            <PrivateRoutes path="/passbook" component={Passbook} exact />
            <PrivateRoutes path="/bonus" component={Bonus} exact />
            <PrivateRoutes path="/invite" component={Invite} exact />
            <PrivateRoutes path="/withdraw" component={Withdraw} exact />
            <PrivateRoutes path="/deposit-cash" component={DepositCash} exact />
            <PrivateRoutes path="/report-problem" component={Report} exact />
            <PrivateRoutes path="/offers" component={Offers} exact />
            <PrivateRoutes path="/reported-problems" component={ReportedProblems} exact />
            <Redirect from="/tearms-of-service" to="/terms-of-service" exact />
            <Redirect to="/" />
        </Switch>
    </ScrollToTop>
)

export default Routes