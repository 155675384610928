import React from 'react'
import Footer from '../auth/Footer'
import Header from '../auth/Header'

const CustomerOnboarding = () => {
    return (
        <div>
            <Header />
            <section className="site-content about-page-section cmn-banner-bg cmn-pad">
                <div className="container">
                    <div className="about-us-content desclaimer">
                        <h3 className="text-center page-title">Customer Onboarding</h3>
                        <div className='about-details-sec'>
                            <h3>Customer Onboarding Policy</h3>
                            <p>At Go Rummy, we prioritize security, responsible gaming, and a seamless onboarding experience for all players. This policy outlines the necessary steps and requirements for users to register, verify, and begin playing on our platform.</p>

                            <h3>Registration Process</h3>
                            <p>To create an account on Go Rummy, users must provide the following details:</p>
                            <ol>
                                <li>User Name (must be unique and should not contain special characters)</li>
                                <li>Email Address (must be valid and in use)</li>
                                <li>Mobile Number (must be linked to a valid SIM)</li>
                                <li>Password (minimum 8 characters, including at least one number and one special character)</li>
                            </ol>

                            <h3>Verification Process</h3>
                            <ol>
                                <li>Email Verification: Users must confirm their email by clicking on a verification link sent to their registered email address or submit the One-Time Password (OTP) sent to their email address</li>
                                <li>Mobile Number Verification: Users must verify their mobile number by entering a One-Time Password (OTP) sent via SMS</li>
                                <li>Password Security: It is advised that users set a strong password and do not share it with anyone under any circumstances</li>
                            </ol>

                            <h3>Account Verification (KYC Policy)</h3>
                            <p>As per regulatory requirements and to ensure a safe gaming environment, basic KYC verification is mandatory before deposits and full KYC verification before withdrawals.</p>
                            <p>Accepted KYC Documents: (Users must submit clear scanned copies of the following documents)</p>
                            <ol>
                                <li>Aadhaar Card (Mandatory for identity verification)</li>
                                <li>PAN Card (Mandatory for withdrawals and tax compliance)</li>
                            </ol>
                            <p>KYC Verification Timeline:</p>
                            <ol>
                                <li>KYC verification is instant, processed through Auto API-enabled verification. It however should not take more than 15 mins subject to server response from the regulators</li>
                                <li>If documents are unclear or mismatched, a manual verification process will be initiated, taking up to 72 hours post completion / submission of all required documents</li>
                            </ol>
                            <p>Failed KYC Attempts:</p>
                            <ol>
                                <li>Users have a maximum of 2 attempts to submit correct documents</li>
                                <li>Upon the second failure, the account will be temporarily suspended until proper documents are provided and manually verified by support team of Go Rummy</li>
                            </ol>
                            <p>No Gameplay Without KYC:</p>
                            <ul>
                                <li>Users cannot start playing with free credits before verification</li>
                            </ul>
                            <p>Users are requested to refer our KYC policy for further details.</p>

                            <h3>Deposits and Payments</h3>
                            <ol>
                                <li>Minimum deposit limit: ₹99 per transaction</li>
                                <li>Maximum deposit limit: ₹49,999 per transaction</li>
                                <li>First Deposit Bonus: New users receive a ₹500 bonus on their first deposit</li>
                            </ol>
                            <h5>Deposit Methods:</h5>
                            <ol>
                                <li>UPI (Google Pay, PhonePe, Paytm, etc.)</li>
                                <li>Debit/Credit Cards (All major cards in India are supported)</li>
                                <li>Net Banking (All major banks in India are supported)</li>
                            </ol>

                            <h3>User Onboarding Experience</h3>
                            <p>No tutorial or guide for first-time users; users are expected to familiarize themselves with the platform. Users are required to acknowledge and accept the Responsible Gaming Policy before starting gameplay.</p>

                            <h3>Fraud Prevention & Security</h3>
                            <p>To maintain a fair and secure gaming environment:</p>
                            <ol>
                                <li>Each player is allowed only one account</li>
                                <li>Automated fraud detection systems will flag the following
                                    <ul>
                                        <li>Registration attempts with duplicate KYC documents</li>
                                        <li>Accounts using the same mobile number multiple times</li>
                                        <li>Mismatched name details between KYC documents and bank details</li>
                                        <li>Strict anti-account sharing measures will be in place</li>
                                    </ul>
                                </li>
                                <li>Permanent account closure and/or legal action will be taken against users providing fraudulent details</li>
                            </ol>

                            <h3>Account Suspension & Rejection</h3>
                            <p>An account may be rejected or suspended under the following conditions:</p>
                            <ol>
                                <li>Repeated use of the same mobile number for multiple registrations</li>
                                <li>Same set of KYC documents used across multiple accounts</li>
                                <li>Mismatched names in KYC and bank/API verification</li>
                            </ol>

                            <h3>Flagged Accounts Review</h3>
                            <ol>
                                <li>Accounts flagged for suspicious activity will undergo manual verification</li>
                                <li>If fraudulent activity is confirmed, the account will be permanently suspended</li>
                                <li>Users found violating fraud policies will have all deposits and winnings forfeited</li>
                            </ol>

                            <h3>Withdrawals & Processing Fees</h3>
                            <ol>
                                <li>Name on KYC documents and bank account must match exactly for withdrawal approval</li>
                                <li>Withdrawable amount: Only winnings (not bonus amounts) can be withdrawn</li>
                                <li>A 30% TDS is deducted on withdrawals as per applicable tax laws. Users are requested to refer out TDS policy for further details</li>
                            </ol>

                            <h3>Withdrawal Methods</h3>
                            <ol>
                                <li>UPI</li>
                                <li>Bank Transfer</li>
                                <li>Net Banking</li>
                            </ol>
                            <p>Processing Time: Withdrawals are processed within 24 hours post-verification (subject to working hours / days or force majeure conditions).</p>

                            <h3>Support & Communication</h3>
                            <p>Users can contact support through:</p>
                            <ol>
                                <li>Email: <a href="mailto:support@rummy246.in">support@rummy246.in</a></li>
                                <li>Phone: <a href="tel:1800 890 2466">1800 890 2466</a></li>
                            </ol>

                            <h3>Incomplete Onboarding Restrictions</h3>
                            <ol>
                                <li>Players cannot access deposits without completing basic KYC or withdrawals without completing full valid KYC</li>
                                <li>No gameplay is allowed until basic KYC verification is complete</li>
                                <li>Pop-up notifications will be used to remind users to complete their onboarding and KYC verification</li>
                            </ol>

                            <p>This policy ensures a smooth, secure, and compliant onboarding experience while adhering to legal and industry standards.</p>
                            <p>Play responsibly and enjoy Go Rummy!</p>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default CustomerOnboarding