import React from 'react';
// import Home
import Footer from '../auth/Footer';
import Header from '../auth/Header';


const TDS = () => {
    return (
        <div>
            <Header />
            <section className="site-content about-page-section cmn-banner-bg cmn-pad">
                <div className="container">
                    <div className="about-us-content desclaimer">
                        <h3 className="text-center page-title">TDS Policy</h3>
                        <div className='about-details-sec'>
                            <p>Go Rummy adheres strictly to the provisions of the Income Tax Act, 1961, concerning Tax Deducted at Source (TDS) on winnings from online gaming. This policy defines the legal framework, obligations, and procedures related to TDS deductions from user winnings to ensure full compliance with statutory tax regulations.</p>
                            <h3>Applicability of TDS</h3>
                            <p>1. Pay TDS only on Net Winning</p>
                            <h5 className='ps-4'>Net Winnings = Total withdrawals in a financial year – Total deposits in a financial year – Opening balance at the start of the financial year</h5>
                            <p>2. TDS will be deducted from the Net Winnings included in the withdrawal amount at the time of withdrawal or at the end of the financial year</p>
                            <p>3. The applicable TDS rate is 30%, and no exemptions or deductions under other provisions of the Income Tax Act apply</p>
                            <p>4. No TDS will be deducted if there are no Net Winnings</p>
                            <p>5. If during the year, there are multiple withdrawals and multiple deposits, Net Winings will be calculated for the current financial year every time and TDS if applicable, shall be charged accordingly</p>
                            <p>6. Withdrawal can be made tax-free till respective TDS liability does not exceed the Excess Tax paid (TDS deducted)</p>
                            <p>7. Financial year end balances will be considered as withdrawal for that financial year and TDS will be applicable accordingly</p>
                            <p>8. Prizes other than Cash is subject to 30% TDS payable in advance to Go Rummy.</p>

                            <h3>TDS Deduction and Reporting</h3>
                            <p>The deducted TDS will be remitted to the Government of India in accordance with the prescribed timelines</p>
                            <p>Users will be issued Form 16A to facilitate tax filing compliance.</p>
                            <p>Users must provide a valid PAN (Permanent Account Number) for TDS deductions. Failure to provide PAN may result in TDS deduction at a higher rate as per applicable tax laws.</p>

                            <h3>Filing and Refund</h3>
                            <p>TDS once debited during the financial year cannot be reversed despite the player ends up with negative cash flow on subsequent games, post deduction. Players can claim a refund from the Income Tax Department while filing the ITR</p>
                            <p>Users can claim a TDS refund while filing their Income Tax Return (ITR), if applicable.</p>
                            <p>Go Rummy is responsible only for TDS deductions and deposits and does not provide tax advisory services.</p>
                        </div>
                    </div>
                </div>
            </section>



            {/* <!--***** Footer *****--> */}
            <Footer />
        </div>
    )
}

export default TDS