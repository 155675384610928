import React from 'react'
import Header from '../auth/Header'
import Footer from '../auth/Footer'

const KycPolicy = () => {
    return (
        <div>
            <Header />
            <section className="site-content about-page-section cmn-banner-bg cmn-pad">
                <div className="container">
                    <div className="about-us-content desclaimer">
                        <h3 className="text-center page-title">KYC Policy</h3>
                        <div className='about-details-sec'>
                            <h3>Introduction</h3>
                            <p>At Go Rummy (rummy246.in), we are committed to providing a secure and responsible gaming environment. As part of this commitment, we require all users to complete the basic Know Your Customer (KYC) verification process before making any deposit and complete KYC verification process before making any withdrawal. KYC verification ensures compliance with regulatory requirements and enhances security, fraud prevention, and promotes responsible gaming practices. It is also in line with gaming laws, including the authorities it is licensed by.</p>

                            <h3>Why KYC is Mandatory</h3>
                            <p>KYC verification is essential for the following reasons:</p>
                            <ol>
                                <li>Regulatory Compliance: Adherence to government regulations and gaming laws</li>
                                <li>Fraud Prevention: Protecting users from identity theft and unauthorized transactions</li>
                                <li>Secure Transactions: Ensuring that financial transactions are legitimate and safe</li>
                                <li>Fair Gaming: Ensuring that all players are real individuals and not engaging in fraudulent activities</li>
                            </ol>

                            <h3>When is KYC Required?</h3>
                            <ol>
                                <li>Basic KYC verification must be completed before making any deposit on our platform</li>
                                <li>Users cannot withdraw funds without a successfully verified KYC</li>
                                <li>Verification is required in case of security concerns, account recovery, or suspected fraudulent activity</li>
                            </ol>

                            <h3>Accepted KYC Documents</h3>
                            <p>To complete the KYC process, users must submit the following documents:</p>
                            <ol>
                                <li>Aadhaar Card (Mandatory for identity verification)</li>
                                <li>PAN Card (Mandatory for withdrawals and tax compliance)</li>
                            </ol>

                            <h3>KYC Verification Process</h3>
                            <p>We offer an instant auto-verification process using API-based document validation. Follow these steps to complete your KYC:</p>
                            <ol>
                                <li>Login to your Go Rummy Account: Visit <a href="rummy246.in">rummy246.in</a> and log in with your registered credentials</li>
                                <li>Access KYC Section: Navigate to the "My Profile" section and click on "KYC Verification"</li>
                                <li>Upload Required Documents: Enter your Aadhaar and PAN details for verification</li>
                                <li>Auto-Verification: Our system will instantly verify your details using an API-enabled authentication process</li>
                                <li>Completion Confirmation: Once verified, you will receive a confirmation notification via email and pop-up alert on the platform</li>
                                <li>
                                    <p>Failed Verification & Document Rejection: In the case of failed verification, users are allowed a maximum of two failed attempts to submit correct documents. If documents are rejected twice, the account will be temporarily suspended until further verification is completed.</p>
                                    <p>KYC verification is only available to the players who are not residents of restricted territories. The state laws of following states come under restricted territories wherein the users cum residents are not permitted to play cash rummy games.</p>
                                    <ol>
                                        <li>Tamil Nadu</li>
                                        <li>Andhra Pradesh</li>
                                        <li>Telangana</li>
                                        <li>Karnataka</li>
                                        <li>Odisha</li>
                                        <li>Assam</li>
                                        <li>Sikkim</li>
                                        <li>Nagaland</li>
                                    </ol>
                                    <p>Users shall abide by these state laws and will not use Go Rummy’s Services to deposit cash, withdraw cash, or participate in cash games on Junglee Rummy while residing in the above-mentioned states.</p>
                                    <p>Users must contact support at <a href="mailto:support@rummy246.in">support@rummy246.in</a> for manual verification assistance.</p>
                                </li>
                            </ol>
                            <p>By submitting your KYC documents, you consent to Go Rummy collecting and processing your personal information as per our Privacy Policy & our Terms and Conditions.</p>
                            <p>The TAT for auto-verification is 15 mins (subject to server response from the regulators). However, any manual verification can take up to 72 hours subject to submission of completion / submission of all required documents.</p>
                            <p>Users are also strictly advised to update Go Rummy with their fresh KYC details within 7 working days of any change or before initiating withdrawal post change, or whichever is earlier.</p>

                            <h3>Withdrawal cum Deposit Policies & Restrictions</h3>
                            <ol>
                                <li>Name Matching Requirement: The name on the bank account must exactly match the name on the submitted KYC documents – PAN card</li>
                                <li>TDS applicability: A 30% TDS will be applicable on all withdrawals as per regulatory guidelines. Kindly refer to our TDS policy for further insights on this</li>
                                <li>Withdrawal Request Processing: Withdrawals will be processed only after KYC verification is successfully completed. Ideally, withdrawal is processed within 24hrs (subject to working hours / days or force majeure conditions)</li>
                                <li>Deposit Limit: Minimum deposit request accepted is ₹ 99/- and maximum deposit allowed is ₹ 49,999/- per user per transaction. Deposits can be done without any restrictions on any given day.</li>
                                <li>Withdrawal Limit: Minimum withdrawal request accepted is ₹ 99/- and maximum withdrawal allowed is ₹ 49,999/- per day. Withdrawal limit is set to 2 per day per user, preferably between 9 AM to 6 PM on all working days (working days refers to bank working days in the state of Maharashtra).</li>
                            </ol>

                            <h3>Notifications & User Alerts</h3>
                            <p>Users will be notified about KYC requirements via:</p>
                            <ol>
                                <li>Pop-up notifications on the platform during login and deposit attempts</li>
                                <li>Email notifications to the registered email ID</li>
                                <li>Security & Fraud Prevention Measures</li>
                            </ol>

                            <h3>Data Security</h3>
                            <p>All uploaded documents are encrypted and stored securely, ensuring 100% confidentiality</p>

                            <h3>Strict Rules Against Account Sharing</h3>
                            <p>Users are prohibited from sharing their accounts with others</p>
                            <p>Legal Action for Fake Details: Any false information submitted will result in account termination and may lead to legal consequences</p>

                            <h3>Responsible Gaming Commitment</h3>
                            <p>We ensure compliance with ethical gaming practices and player protection policies</p>
                            <p>The decision on KYC and onboarding of Go Rummy management is final in case of any disputes.</p>

                            <h3>Contact Support</h3>
                            <p>For any KYC-related queries or assistance, reach out to our support team:</p>
                            <ol>
                                <li>Support Email: <a href="mailto:support@rummy246.in">support@rummy246.in</a></li>
                                <li>Developer Support: <a href="mailto:developer@rummy246.in">developer@rummy246.in</a></li>
                            </ol>
                            <p>We are here to ensure a seamless and secure gaming experience for you. Complete your KYC today and enjoy hassle-free gaming at Go Rummy!</p>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default KycPolicy